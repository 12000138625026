$(function () {
    $(".LoginSubmit").click(function (e) {
        e.preventDefault();
        var formValid = $("#login").valid();
        if (formValid) {
            if (typeof grecaptcha === "object") {
                grecaptcha.execute();
            } else {
                submitLoginForm();
            }
        } else {
            $("html, body").animate(
                {
                    scrollTop: $(".input-validation-error").first().offset().top,
                },
                250,
            );
        }
    });
});

function recaptchaCallBack(token) {
    submitLoginForm();
}

function submitLoginForm() {
    var form = $("#login");
    form.submit();
}
